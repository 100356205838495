import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import Logo1 from '../../assets/costranslogo.png'
import Logo2 from '../../assets/costranslogo2.png'


export default function Navbar(props) {

  const [open, setOpen] = useState(null)
  const [mobileNav, setMobileNav] = useState(false)
  const [navblack, setNavblack] = useState(false)

  function setOpenSection(num) {
    if (open === num) {
      setOpen(null)
    } else {
      setOpen(num)
    }
  }

  function openMobileNav() {
    mobileNav ? setMobileNav(false) : setMobileNav(true)
  }

  var href = window.location.href.split("/")[3]

function setNav() {
    navblack ? setNavblack(false) : setNavblack(true)
}


    return (
      <div className={href === "humanitarianism" ? "hidden" : ""} > 
      <div className={href !== "" ? "navbar full-white-2 " : "navbar fade-in2"}>

<Link className="logo-link" to={`/`}> 
          <div className="navbar-left shadow-drop-2-center">
              <div className={href !== "" ? "logo black" : "logo white-gradient "}>
              <div className={href !== "" ? "gradient-textb font-14 abs-2" : "gradient-text font-14 abs-2"}>Studio Thirty Two</div>
              <div className={href !== "" ? "gradient-textb font-14 abs-3" : "gradient-text font-14 abs-3"}>
                  32
              </div>
              </div>
          </div>
          </Link>

          <div className="navbar-right">

            <Link to={`/paintings`}>

              <div className={href !== "" ? "black nav-btn smaller uppercase bolder text-black" : "white-gradient nav-btn smaller uppercase bolder gradient-text2"}>
                 Shop Paintings
              </div>
              </Link>

              <Link to={`/photographs`}>
              <div className={href !== "" ? "black nav-btn smaller uppercase bolder text-black" : "white-gradient nav-btn smaller uppercase bolder gradient-text2"}>
                  Shop Portraits
              </div>
              </Link>

              <Link to={`/prints`}>
              <div className={href !== "" ? "black nav-btn smaller uppercase bolder text-black" : "white-gradient nav-btn smaller uppercase bolder gradient-text2"}>
                  Shop Prints
              </div>
              </Link>



              <Link to={`/work`}>
              <div className={href !== "" ? "black nav-btn smaller uppercase bolder  text-black" : "white-gradient nav-btn smaller uppercase bolder gradient-text2"}>
                  Commercial Portfolio
              </div>
              </Link>

              <Link to={`/humanitarianism`}>
              <div className={href !== "" ? "black nav-btn smaller uppercase bolder  text-black" : "white-gradient nav-btn smaller uppercase bolder gradient-text2"}>
                  Humanitarian Photography
              </div>
              </Link>

              <Link to={`/aboutme`}>
              <div className={href !== "" ? "hidden black nav-btn smaller uppercase bolder  text-black" : "hidden white-gradient nav-btn smaller uppercase bolder gradient-text2"}>
                  About Me
              </div>
              </Link>
          </div>

          <div onClick={openMobileNav} className="mobile-navbar-right">
            <span className={mobileNav ? href !== "" ? "hamburger rotate-45l" : "hamburger rotate-45l"  
            : href !== "" ? "hamburger black-back-gradient" : "hamburger white-back-gradient"}>

            </span>
            {!mobileNav ?
            <span className={href !== "" ? "hamburger black-back-gradient" : "hamburger white-back-gradient" }>
            </span> : null }

            <span className={mobileNav ? "hamburger rotate-45r" : href !== "" ? "hamburger black-back-gradient" : "hamburger white-back-gradient"}>
                
            </span>
          </div>

        { mobileNav ? 
          <div onClick={openMobileNav} className={"mobile-navbar fade-in2"}>
            <div className="overlay"></div>

              <Link to={`/paintings`}>


              <div className="nav-btn smaller uppercase white bolder">
                  Shop Paintings
              </div>
              </Link>

     
              <Link to={`/photographs`}>
              <div className="nav-btn smaller uppercase white bolder">
                  Shop Portraits
              </div>
              </Link>


              <Link to={`/prints`}>

              <div className="nav-btn smaller uppercase white bolder">
                  Shop Prints
              </div>
              </Link>


  
 


              <Link to={`/work`}>


              <div className="nav-btn smaller uppercase white bolder">
              Commercial Portfolio
                  
              </div>
              </Link>

              <Link to={`/humanitarianism`}>
              <div className="nav-btn smaller uppercase white bolder">
                  Humanitarian Photography
              </div>
              </Link>
         
              <Link to={`/aboutme`}>

             
<div className="hidden nav-btn smaller uppercase white bolder ">
    About Me

    
</div>
</Link>

          </div> : null }
      </div>
      
          </div>
        );
    }